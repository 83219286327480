import moment from "moment";

export function transformArticle(article) {
    const { metadata = {}, title, subtitle, authorEmail, category = {}, publishStartDate, components = [] } = article;
    const date = (publishStartDate ? moment(publishStartDate).format("MMMM Do, YYYY") : null);
    const byline = [authorEmail, category.name, date].filter(val => !!val);
    return {
        banner: {
            src: metadata.thumbnailURL,
            alt: metadata.excerpt
        },
        heading: title,
        subtitle,
        byline: byline.length > 0 ? byline.join(' | ') : null,
        caption: metadata.excerpt,
        content: Array.isArray(components) ? components.map(o => {
            const { role } = o
            if (role === "photo") {
                return {
                    role,
                    src: o.URL,
                    alt: o.accessibilityCaption,
                    caption: o.caption
                }
            }
            return o
        }) : []
    };
}