import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import Amplify, { Auth } from "aws-amplify";
import reportWebVitals from "./reportWebVitals";
import configureStore from "./storeConfig";
import App from "./App/containers";
import AppleTouchIcon from "./DocumentHead/AppleTouchIcon";
import "./styles/index.scss";
import 'normalize.css';
import awsExports from "./aws-exports";
import apiConfig from "./api-config";
import CustomAuthStorage from './util/CustomAuthStorage';
import { withRUMListener, withGoogleAnalyticsListener } from "@osu/wm-react-analytics";

Auth.configure({ storage: new CustomAuthStorage(apiConfig.customAuthStorage) });
Amplify.configure({ ...awsExports, ...apiConfig });

const store = configureStore();
let awsRUM = {}
try {
  const rumConfig = require("./rumConfig.json")
  Object.assign(awsRUM, rumConfig)
} catch (error) {
  awsRUM = {}
}

let WrappedApp
if(awsRUM?.appMonitorId) {
  WrappedApp = (initialProps = {}) => withGoogleAnalyticsListener((secondaryProps = {}) => withRUMListener(App)({
    ...secondaryProps,
    ...awsRUM,
    cwr: `${process.env.PUBLIC_URL}/cwr.js`
  }))(initialProps)
} else {
  WrappedApp = (props = {}) => withGoogleAnalyticsListener(App)(props)
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <AppleTouchIcon />
        <WrappedApp />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
