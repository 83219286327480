import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SadBrutusImage from "../assets/sad-brutus.jpg";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            overflow: "hidden",
            position: "relative",
            "& img": {
                border: "var(--img-border)",
                borderRadius: "8px",
                display: "block",
                height: "100%"
            }
        },
        heading: {
            fontWeight: 600
        },
        image: {
            width: "100%"
        }
    }
});

function Error(props) {
    const classes = useStyles();
    const { title, ...otherProps } = props;
    return (
        <Box className={classes.root} {...otherProps}>
            <Typography component="h1" variant="h4" className={classes.heading}>Something Went Wrong</Typography>
            <Typography component="h2" variant="subtitle1" color="secondary">{title}</Typography>
            <div><img src={SadBrutusImage} alt="Sad Brutus" className={classes.image} /></div>
            {props.children}
        </Box>
    );
}

Error.propTypes = {
    title: PropTypes.string.isRequired
};

export default Error;