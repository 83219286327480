import React from "react";
import { Link } from "react-router-dom";
import { Container, Typography } from "@material-ui/core";
import Error from "../../Common/components/Error";

export default function PageNotFound(props) {
    return (
        <Container maxWidth="md" component="div">
            <Error title="Page Not Found">
                <Typography component="div" variant="body1">
                    <p>The page you're looking for could not be found.  Return <Link to="/">Home</Link>.</p>
                </Typography>
            </Error>
        </Container>
    );
}