import { ARTICLE, ARTICLE_STATUS } from "../actions-index.js";

export function article(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {
        case ARTICLE:
            return { ...state, data: payload };
        case ARTICLE_STATUS:
            return { ...state, status: payload };
        default:
            return state;
    }
}