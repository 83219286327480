import React, { useContext } from "react";
import {
  /* PageHeading, */
  withPageHeading,
  TableOfContents,
  Mui,
  TOC,
} from "@osu/react-ui";
import Routes from "../../Routes/components";

const useStyles = Mui.makeStyles((theme) => {
  return {
    root: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'row-reverse'
      },
      //minHeight: "calc(100vh - 42px - var(--nav-height) - var(--footer-height))" with NavBar
      minHeight: "calc(100vh - 42px - var(--footer-height))"
    },
    toc: {
      zIndex: theme.zIndex.appBar - 2
    },
  };
});

function Main(props = {}) {
  const { className, unauthorized, loading, spacingTop, ...rest } = props;
  const classes = useStyles();
  const { exists } = useContext(TOC.Context);

  return (
      <Mui.Box flexGrow={100} className={`${className} ${classes.root}`.trim()} {...rest}>
        <Mui.Hidden smDown>
          <TableOfContents
            spacingTop={spacingTop}
            PaperProps={{
              className: classes.toc,
            }}
            StylizedLinkProps={{ color: "primary" }}
          />
        </Mui.Hidden>
        <Mui.Container className="margin-y-4" maxWidth="lg">
          {/* <Mui.Hidden smDown>
            <PageHeading />
          </Mui.Hidden> */}
          <Mui.Hidden mdUp>
            {/* <header className="display-flex">
              <PageHeading />
            </header> */}
            {exists && <TableOfContents variant="popover" />}
          </Mui.Hidden>
          <Routes />
        </Mui.Container>
      </Mui.Box>
  );
}

Main.defaultProps = {
  className: "",
  tabIndex: "-1",
  component: "main",
};

export default withPageHeading(Main);