import { lazy } from "react";
import { APPLICATION_NAME, ALUMNI_APP, OHIO_STATE_APP, WELLNESS_APP } from "../util/constants";

const Article = lazy(() => import("../Article/containers"));
const Home = lazy(() => import("../Home/components"));
const LogIn = lazy(() => import("../Authentication/components/LogIn"));
const LogOut = lazy(() => import("../Authentication/components/LogOut"));
const title = APPLICATION_NAME;
const Unauthorized = lazy(() => import("./views/Unauthorized"));

const PATHS = [
    { path: "/", title, Component: Home },
    { path: `/${ALUMNI_APP.pathKey}/article`, title, Component: Article },
    { path: `/${OHIO_STATE_APP.pathKey}/article`, title, Component: Article },
    { path: `/${WELLNESS_APP.pathKey}/article`, title, Component: Article }
];

const authenticationFlowPaths = [
    {
        path: '/login',
        Component: LogIn 
    },
    {
        path: '/logout',
        Component: LogOut 
    },
    {
        path: '/unauthorized',
        Component: Unauthorized 
    }
]

export {
    authenticationFlowPaths,
    PATHS as default
}