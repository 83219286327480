import { API } from "aws-amplify";
import { ARTICLE, ARTICLE_STATUS } from "../actions-index.js";
import { transformArticle } from "./transform";
import { ACTION_STATUS_ERROR, ACTION_STATUS_LOADING, ACTION_STATUS_NOT_FOUND, ACTION_STATUS_SUCCESS } from "../util/constants";
import { buildAction } from "../util/functions";

export function getArticle(id) {
    return async dispatch => {
        try {
            dispatch(buildAction(ARTICLE_STATUS, ACTION_STATUS_LOADING));
            const response = await API.get(process.env.REACT_APP_COMPASS_API_NAME, `/public/content/article/${id}`);
            dispatch(buildAction(ARTICLE, transformArticle(response.data.article)));
            dispatch(buildAction(ARTICLE_STATUS, ACTION_STATUS_SUCCESS));
        } catch(error) {
            console.error("Get Article Error: ", error);
            const status = error?.response?.status;
            if(status === 404) {
                dispatch(buildAction(ARTICLE_STATUS, ACTION_STATUS_NOT_FOUND));
            } else {
                dispatch(buildAction(ARTICLE_STATUS, ACTION_STATUS_ERROR));
            }
        }
    };
}