export function buildAction(type, payload) {
    return { type, payload };
}

export function omitReduxProps(props = {}) {
    const clonedObject = Object.assign({}, props)
    delete clonedObject.history
    delete clonedObject.location
    delete clonedObject.match
    delete clonedObject.staticContext
    delete clonedObject.dispatch

    return clonedObject
}

export function isUnauthenticated(error) {
    const status = (error?.response && error?.response?.status) ? error.response.status : null;
    const unauthorizedErrors = ["The user is not authenticated", "No current user", "Request failed with status code 401"];
    
    return unauthorizedErrors.includes(error) || status === 401
}