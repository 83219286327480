import AlumniAppIcon from "../Common/assets/icons/alumni-app.png";
import OhioStateAppIcon from "../Common/assets/icons/ohio-state-app.png";
import WellnessAppIcon from "../Common/assets/icons/wellness-app.png";

const itunesBaseUrl = "https://apps.apple.com/us/app";
const playStoreBaseUrl = "https://play.google.com/store/apps/details";

export const ACCESSIBLE_REFLOW_HEIGHT = `399px`;
export const ACTION_STATUS_ERROR = "error";
export const ACTION_STATUS_LOADING = "loading";
export const ACTION_STATUS_NOT_FOUND = "notfound";
export const ACTION_STATUS_SUCCESS = "success";
export const ALUMNI_APP = {
    icon: AlumniAppIcon,
    itunesId: "1470303430",
    itunesUrl: `${itunesBaseUrl}/1470303430`,
    name: "Ohio State Alumni",
    pathKey: "alumni",
    playStoreId: "edu.osu.alumni",
    playStoreUrl: `${playStoreBaseUrl}?id=edu.osu.alumni`
};
export const APPLICATION_NAME = "Share OSU Content";
export const COLOR_LIGHT_OHIO_STATE = "#de2d1e";
export const COLOR_LIGHT_WELLNESS = "#b487ea";
export const COLOR_NORMAL_OHIO_STATE = "#ba0c2f";
export const COLOR_NORMAL_WELLNESS = "#74147b";
export const FORCED_AUTHENTICATION = process.env.REACT_APP_FORCED_AUTHENTICATION === "true";
export const ITUNES_BASE_URL = itunesBaseUrl;
export const MAIN_ID = 'page-content';
export const OHIO_STATE_APP = {
    icon: OhioStateAppIcon,
    itunesId: "446442963",
    itunesUrl: `${itunesBaseUrl}/446442963`,
    name: "Ohio State",
    pathKey: "ohiostate",
    playStoreId: "edu.osu.osumobile",
    playStoreUrl: `${playStoreBaseUrl}?id=edu.osu.osumobile`
};
export const OSU_NAV_ID = 'osu_navbar';
export const PLAYSTORE_BASE_URL = playStoreBaseUrl;
export const SEARCH_PARAMS = {
    ARTICLE_ID: "articleId",
    IDENTIFIER: "identifier"
};
export const STYLESHEET_LENGTH = document && document.styleSheets ? document.styleSheets.length : 99;
export const TOC_WIDTH = `15rem`;
export const WELLNESS_APP = {
    icon: WellnessAppIcon,
    itunesId: "1490410940",
    itunesUrl: `${itunesBaseUrl}/1490410940`,
    name: "Ohio State: Wellness",
    pathKey: "wellness",
    playStoreId: "edu.osu.wellness",
    playStoreUrl: `${playStoreBaseUrl}?id=edu.osu.wellness`
};