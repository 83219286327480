import { SET_USER, USER_STATUS } from "../actions-index";
import { transformUser } from "./transform";

export function recievedSetUser(user) {
    return {
        type: SET_USER,
        payload: Object.keys(user || {}).length > 0 ? transformUser(user) : null
    }
}

export function setUserStatus(status) {
    return {
        type: USER_STATUS,
        payload: status
    }
}

export function receiveAmplifyHubUser(response = {}) {
    return function (dispatch) {
        if(response?.status) {
            dispatch(setUserStatus(response?.status))
        }
        if(['error', 'cleared'].includes(response?.status)) {
            dispatch(recievedSetUser({}));
        } else if(response?.osuid || response?.displayName) {
            dispatch(recievedSetUser(response));
        } 
    }
}