import React, { Fragment, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet, PageHeadingContext } from "@osu/react-ui";
import { MAIN_ID } from "../../util/constants";

export function RenderableView({ pathInformation = {}, renderProps = {} }) {
    const { props = {}, Component, noIndex, title: pathTitle, titleProps } = pathInformation
    const { /* setHeading, */ setOptions } = useContext(PageHeadingContext)
    const title = typeof pathTitle === 'function' ? pathTitle(renderProps) : pathTitle
    const authRequired = pathInformation.authRequired
    
    useEffect(() => {    
        /* setHeading(title) */
        document.title = title;
        document.getElementById(MAIN_ID).focus();
    }, [/* setHeading, */ title])

    useEffect(() => {    
        const options = Object.assign({ variant: 'h2', className: 'margin-bottom-2' }, titleProps)
        setOptions(options)
    }, [setOptions, titleProps])

    return <Fragment>
        {(noIndex || authRequired) && <Helmet>
            <meta id="meta-hidden" name="robots" content="noindex" />
        </Helmet>}
        <Component 
            {...renderProps}  
            {...props} 
        />
    </Fragment>
}

RenderableView.defaultProps = {
    renderProps: {},
    pathInformation: {
        props: {},
        noIndex: false, 
        authRequired: false,
    }
}

RenderableView.propTypes = {
    pathInformation: PropTypes.shape({
        path: PropTypes.string.isRequired, 
        props: PropTypes.object, 
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired, 
        titleProps: PropTypes.object, 
        Component: PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.func]).isRequired, 
        noIndex: PropTypes.bool, 
        authRequired: PropTypes.bool
    }).isRequired, 
    renderProps: PropTypes.object, 
    createTitle: PropTypes.func,
}

export default RenderableView